.TodoCounter {

    margin-bottom: 20px;
    width: 180px;
    height: 180px;
    border-radius: 50%;
    background:var(--secondary-color);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;

    .CounterTitle{
        color:#FFFFFF;
        font-size: 18px;
        padding-bottom: 5px;
    }
    .Counter{
        color:#fff;
        padding-bottom: 10px;
        position: relative;
        bottom:5px;
        font-size: 22px;
        font-weight: bold;
        padding-top: 0;
        margin: 0;
    }

}