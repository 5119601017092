@keyframes balance {
    0% {transform: rotate(0deg);}
    50%{ transform: rotate(30deg);}
    100%{ transform: rotate(-30deg);}
  }

.TodoCompleted{
    background-color: rgb(235, 235, 235);
}

.TodoNotCompleted{
    background-color: #ffff;
}

.TodoItem{
   height: 60px;
    box-shadow: 2px 4px 6px #0000004b !important;
    border-radius: 15px;
    color: var(--font-color);
    transition: all 0.3s;

    .Completed{

        text-decoration:line-through;

    }
    
    .TodoCheck,.TodoRemove,.ShowTodo{
        cursor: pointer;
        color:#585858; 
    }

    .ShowTodo{
        font-size: 20px;
        margin-right: 10px;
    }

    .TodoRemove{
        transition: 0.3s;
        
        &:hover{
            animation-name: balance;
            animation-duration: 2s;
            animation-iteration-count: infinite;
            animation-timing-function: ease;
            animation-direction: alternate;
        }
    }

}
