
.btn-container{

    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #ffff;
    position: relative;
    bottom: 40px;

    .CreateTodoButton{
        box-sizing: border-box;
        width:65px ;
        height: 65px ;
        padding: 0 ;
        background-color: var(--secondary-color);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        font-size: 30px;
        font-weight: bold;
        color: #FFFF;
        //border: 8px solid #ffff;
        box-shadow: none;
        transition: all 0.3s;
        z-index: 6;
        

        &:hover{
            background-color: var(--btn-color-hover);
            box-shadow: 0px 0px 10px 3px var(--btn-color-hover-shadow);
        }

    }
}

