 
 .SeeTodoModal{
     display: block;
     height: 100vh;
     width: 100%;   
     transition: all 0.1s; 
     z-index: 9;
     position: absolute;
     bottom: 60px;  
 
     .TodoModal{
         position: absolute;
         bottom:0px;
         background-color: #ffff;
         border-radius: 40px ;
         height: 550px;
         z-index: 9;
         overflow: hidden;
 
         .TodoModalHeader{
 
             .TodoModalHeaderBar{
                 background-color: var(--secondary-color);
                 width: 100%;
                 height: 60px;
                 border: none;
                 position: absolute;
                 top:0;
             }
 
             .TodoModalHeaderLogo{
                 background-color: var(--secondary-color);
                 height: 130px;
                 width: 130px;
                 border-radius: 50%;
                 border: none;
                 position: relative;
                 top: -20px;
                 padding-top: 20px;
                 color: #ffff;
                 font-size: 60px;
                 
             }
 
             .TodoModalHeaderTitle{
 
                 font-size: 30px;
                 color:var(--font-color)
             }
 
 
         }
 
         .WrapModalElements{
 
             .InputsContainer{
                 position: absolute;
                 bottom: 15%;
 
                 .TodoTitleInput,.TodoDescriptionInput{
 
                     border: none;
                     box-shadow: 2px 4px 6px #0000004b !important;
                     border-radius: 15px;
                     color: var(--font-color);
                     width: 95%;
                     outline: none;
                     padding:15px;
                     transition: background 0.3s;
 
                     &::placeholder{
                         color:var(--font-color-placeholder)
                     }

                     &:disabled{
                        background: rgb(235, 235, 235);
                     }
                     
                 }
 
                 .TodoTitleInput{
 
                     height: 50px;
                     margin-top: 20px;
                     margin-bottom: 10px;
                     display: flex;
                     justify-content: center;
 
                 }
 
                 .TodoDescriptionInput{
                     height: 170px;
                     resize: none;
                     margin-bottom: 30px;
                     padding-top: 15px;
                 }
             }
 
             .ButtonsContainer{
 
                 position: absolute;
                 bottom:10px;

                 .Accept{
                     background: #35b735 !important;
                 }
 
                 .Button{
                     border-radius: 50%;
                     border: none;
                     background-color: var(--secondary-color);
                     height: 65px;
                     width: 65px;
                     color:#ffff;
                     display: flex;
                     justify-content: center;
                     align-items: center;
                     transition: all 0.3s;
 
                     &:hover{
                         background-color: var(--btn-color-hover);
                         box-shadow: 0px 0px 10px 3px var(--btn-color-hover-shadow);
                     }
                     &:disabled{
                        background-color: var(--btn-color-hover) !important;
                     }

                     .AcceptEdition{
                        font-size: 40px;
                     }

                     .CloseModal,.CancelEdit{
                        font-size: 40px;
                     }


                    .EditTodo{
                        font-size: 25px;
                    }

                 }  
                 
             
             
             }
         }
     }
 
 }

