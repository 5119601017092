.TodoSearcher{

    height: 60px;
    padding: 0px 0px 0px 20px;
    background-color:#ffff ;
    width: 100%;
    border-radius: 20px;
    filter:drop-shadow(2px 4px 6px #0000004b);
    position:relative;
    display: flex;
    align-items: center;
    

    input{
        border: none;
        outline: none;
        height: 100%;
        width: 90%;
        padding-right:20px ;

        &::placeholder{
            color: var(--font-color-placeholder);
        }
    
    }

    .clearInput{
        color:var(--font-color);
        position: absolute;
        right: 20px;
        font-size: 20px;

    }

}