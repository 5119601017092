:root{
    --font-color:#585858;
    --font-color-placeholder:#7e7e7e;
    --font-color-light:#9e9e9e;
    --secondary-color:#585858;
    --btn-color-hover: #777777;
    --btn-color-hover-shadow: #bbacac;
    --principal-color:rgb(89, 36, 224);
    --background-principal-gradient: linear-gradient(180deg, rgb(89, 36, 224) 0%, rgba(89, 36, 224, 0.6867121849) 35%, rgba(89, 36, 224, 0.3029586835) 100%);


}