.TodoListContainer{

    width:100%;
    margin-top: 30px !important;
    filter:drop-shadow(2px 4px 6px #0000004b);
    overflow: hidden;

    ::-webkit-scrollbar {
        display: none;
    }
  
    .TodoList{
        
        background-color: #FFFFFF;
        padding-top: 10px; 
        height:58vh;
        overflow-y: scroll;
        overflow-x:hidden; 
        border-radius: 20px;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch; 
        position: relative;
        z-index: 5;
        
        .TodoEmpty{

            color: var(--font-color-placeholder);
            height: 98%;
            flex-direction: column;

            h2{
                margin:20px
            }

        }
        .TodoItems{
            padding-bottom: 15px;   
        }

       
        ::-webkit-scrollbar {
            display: none;
        }

        .TodoLoader,.TodoError{
            height: 90%;
            flex-direction: column;
            color: var(--font-color-placeholder);

            h2 {
                    
                    margin: 20px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    
            
                }
            
        }

    }
}

.TodoBar{
    width: 100% !important;
    height: 77px;
    background: linear-gradient(180deg, rgba(247, 247, 247, 0.4094012605) 24%, rgba(255, 255, 255, 0.5018382353) 8%, rgb(255, 255, 255) 78%);
    position: sticky;
    bottom: -10px;
}


